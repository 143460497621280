import React from "react";
import { toast } from 'react-toastify';
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// icons
import SpeedIcon from "@mui/icons-material/Speed";
import SyncIcon from "@mui/icons-material/Sync";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { NavLink as RouterLink } from "react-router-dom";
import { Typography } from "@mui/material";
import * as ClientContactActions from "../../client/contacts";
import Loading from "../loading/Loading";

interface Props {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 240,
    },
    toolbar: theme.mixins.toolbar,
    logo: {
      width: "calc(100% - 40px)",
      padding: "30px 20px",
      paddingRight: "16px",
      position: "relative"
    },
    link: {
      color: "#fff",
      width: "calc(100% + 10px)",
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.dark,
      },
      "& .MuiListItemIcon-root": {
        color: "#fff",
      },
    },
    activeLink: {
      backgroundColor: theme.palette.primary.dark,
      width: "calc(100% + 10px)",
      "& .MuiListItemIcon-root": {
        color: theme.palette.secondary.main,
      },
    },
    footNote: {
      position: "absolute",
      bottom: 0,
      textAlign: "center",
      paddingBottom: 20,
      width: "100%",
      "& a": {
        color: "white",
        display: "block",
        opacity: ".8",
        textDecoration: "none"
      }
    }
  })
);

const Navigation: React.FC<Props> = (props: Props) => {
  const [isSyncing, setIsSyncing] = React.useState(false);
  const classes = useStyles();

const handleBexioSync = () => {
  setIsSyncing(true);
  toast.info("Bexio Synchronisation wurde gestartet.");  
  ClientContactActions.syncWithBexio().then((response) => {
    toast.success(response.data);
    setIsSyncing(false);
  }, (error) => {
    toast.error(error.response ? error.response.data : error.message);
    setIsSyncing(false);
  })
}

  return (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => props.toggleDrawer(true)}
      onKeyDown={() => props.toggleDrawer(false)}
    >
      <div className={classes.toolbar}>
        <RouterLink to="/">
          <img src="/assets/img/logo.svg" className={classes.logo} />
        </RouterLink>
      </div>
      <Divider />
      <List>
        <ListItem
          button
          component={RouterLink}
          to="/dashboard"
          exact
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <SpeedIcon />
          </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        <ListItem
          button
          component={RouterLink}
          to="/subscriptions"
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText primary={"Abos"} />
        </ListItem>
        <ListItem
          button
          component={RouterLink}
          to="/invoices"          
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText primary={"Rechnungen"} />
        </ListItem>
        <Divider />
        <ListItem
          button
          onClick={!isSyncing && handleBexioSync}
          className={classes.link}
        >
          <ListItemIcon>
            <SyncIcon />
          </ListItemIcon>
          <ListItemText primary={isSyncing ? <Loading isLoading={isSyncing} isInline /> : "Bexio Kontakte synchronisieren"} />
        </ListItem>
      </List>
      <div className={classes.footNote}>
        <a href="https://www.chili.ch" target="_blank"><Typography variant="body2">Powered by Chili Digital AG</Typography></a>
      </div>
    </div>
  );
};

export default Navigation;
