
import Box from "@mui/material/Box";
import { ConfirmProvider } from "material-ui-confirm";
import * as React from "react";
import { useSelector } from "react-redux";
import Header, { drawerWidth } from "../components/header/Header";
import { getCurrentUser } from "../features/user-feature";
import { AppState } from "../interfaces/general/App-State";
import { BexioUser } from "../interfaces/bexio/BexioUser";
import Routes from "../routes";
import { theme } from "../theme";
import { useThunkDispatch } from "../util/thunk-dispatch";

const App: React.FC = () => {
    const asyncDispatch = useThunkDispatch();
    const user = useSelector<AppState, BexioUser>(x => x.user);
    React.useEffect(() => {
        asyncDispatch(getCurrentUser());
    }, []);
    return (
        <>
            <ConfirmProvider>
                <Header />
                <Box sx={{
                    display: "block",
                    flexGrow: 1,
                    [theme.breakpoints.up("lg")]: {
                        width: `calc(100vw - ${drawerWidth + 50}px)`,
                        marginLeft: drawerWidth + "px",
                        padding: "25px 0 25px 25px"
                    },
                    marginTop: "64px",
                    padding: "25px"
                }}>
                    <Routes />
                </Box>
            </ConfirmProvider>
        </>
    );
}

export default App;