import * as React from "react";
import SyncLoader from "react-spinners/SyncLoader";
import ClipLoader from "react-spinners/ClipLoader";
import clsx from "clsx";
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { drawerWidth } from "../header/Header";

interface Props {
    isLoading: boolean;
    isInline?: boolean; // is the spinner inside a button?
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            position: "fixed",
            textAlign: "center",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            [theme.breakpoints.up('lg')]: {
                left: `calc(50% + ${drawerWidth / 2}px)`,
            },
            zIndex: 100
        },
        inlineWrapper: {
            position: "absolute",
            lineHeight: "0",
            left: "50%",
        },
        button: {
            marginTop: theme.spacing(1),
        }
    })
);

const Loading: React.FC<Props> = (props: Props) => {
    const classes = useStyles();

    const renderIsLoading = () => {
        if (props.isLoading) {
            return (
                <div
                    className={clsx(classes.wrapper, {
                        [classes.inlineWrapper]: props.isInline,
                    })}
                >
                    {props.isInline ? (
                        <ClipLoader loading size={20} color={"#ffffff"} />
                    ) : (
                            <SyncLoader loading />
                        )}
                </div>
            );
        }
    }

    return (<>{renderIsLoading()}</>);
}

export default Loading;