import * as React from "react";
import { Switch, Route } from "react-router-dom";
import * as loadable from 'react-loadable';
import Loading from "./components/loading/Loading";
import ProtectedRoute from "./components/protected-route/ProtectedRoute";
import { useSelector } from "react-redux";
import { AppState } from "./interfaces/general/App-State";
import { BexioUser } from "./interfaces/bexio/BexioUser";
import { Request } from "./interfaces/general/Request";


const AsyncDashboardContainer = loadable({ loader: () => import(/* webpackChunkName: "Dashboard" */ "./containers/dashboard/Dashboard"), loading: Loading });
const AsyncHomeContainer = loadable({ loader: () => import(/* webpackChunkName: "Home" */ "./containers/home/Home"), loading: Loading });
const AsyncLoginContainer = loadable({ loader: () => import(/* webpackChunkName: "Login" */ "./containers/authentication/Login"), loading: Loading });
const AsyncLogoutContainer = loadable ({loader: () => import(/* webpackChunkName: "Logout" */ "./containers/authentication/Logout"), loading: Loading});
const AsyncSubscriptionContainer = loadable({ loader: () => import(/* webpackChunkName: "Subscriptions" */ "./containers/subscriptions/Subscriptions"), loading: Loading });
const AsyncAddSubscriptionContainer = loadable({ loader: () => import(/* webpackChunkName: "AddSubscription" */ "./containers/subscriptions/AddSubscription"), loading: Loading });
const AsyncEditSubscriptionContainer = loadable({ loader: () => import(/* webpackChunkName: "EditSubscription" */ "./containers/subscriptions/EditSubscription"), loading: Loading });
const AsyncInvoicesContainer = loadable({ loader: () => import(/* webpackChunkName: "Invoices" */ "./containers/invoices/Invoices"), loading: Loading });
const AsyncInvoiceArchiveContainer = loadable({ loader: () => import(/* webpackChunkName: "InvoiceArchive" */ "./containers/invoices/InvoiceArchive"), loading: Loading });
const AsyncContractContainer = loadable({ loader: () => import(/* webpackChunkName: "Contract" */ "./containers/contract/Contract"), loading: Loading });

const AsyncNotFoundContainer = loadable({ loader: () => import(/* webpackChunkName: "NotFound" */ "./containers/error-pages/NotFound"), loading: Loading });

const Routes: React.FC = () => {
    const requests = useSelector<AppState, Request[]>(state => state.requests);
    const [userRequestStatus, setUserRequestStatus] = React.useState("REQUEST");
    const user = useSelector<AppState, BexioUser>((state) => state.user);


    React.useEffect(() => {
        var userRequestString = requests.filter(x => x.type === "user/GET_CURRENT").length > 0 ? requests.filter(x => x.type === "user/GET_CURRENT")[0].status : "REQUEST";
        setUserRequestStatus(userRequestString);
    }, [requests]);

    return (
        <Switch>

            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != 0} exact path="/dashboard" render={() => <AsyncDashboardContainer />} />
            <Route exact path="/" render={() => <AsyncHomeContainer />} />
            <Route exact path="/login/:url?/:key?" render={() => <AsyncLoginContainer />} />
            <Route exact path="/logout" render={() => <AsyncLogoutContainer /> } />
            <Route exact path="/contract/:id" render={() => <AsyncContractContainer/>}/>
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != 0} exact path="/subscriptions" render={() => <AsyncSubscriptionContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != 0} exact path="/subscriptions/add" render={() => <AsyncAddSubscriptionContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != 0} exact path="/subscriptions/edit/:id" render={() => <AsyncEditSubscriptionContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != 0} exact path="/invoices" render={() => <AsyncInvoicesContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != 0} exact path="/invoices/archive/:subscriptionId" render={() => <AsyncInvoiceArchiveContainer />} />
            
            <Route component={AsyncNotFoundContainer} /> 
        </Switch>
    );
}

export default Routes;