export interface BexioUser {
    [key: string]: any
    id: number,
    salutation_type: "male" | "female",
    firstname: string,
    lastname: string,
    email: string,
    is_superadmin: boolean,
    is_accountant: boolean,
}

export const defaultUser: BexioUser = {
    id: 0,
    salutation_type: "male",
    firstname: "",
    lastname: "",
    email: "",
    is_superadmin: false,
    is_accountant: false,
}