import { ActionType, createAction } from "typesafe-actions";
import { Dispatch } from "redux";
import * as ClientUserActions from "../client/user-management";
import { defaultUser, BexioUser } from "../interfaces/bexio/BexioUser";

const getCurrentUserRequest = createAction("user/GET_CURRENT_REQUEST")();
const getCurrentUserFailure = createAction("user/GET_CURRENT_FAILURE")();
const getCurrentUserSuccess = createAction("user/GET_CURRENT_SUCCESS")<BexioUser>();
const logoutSuccess = createAction("user/LOGOUT_SUCCESS")();

export const getCurrentUser = () => {
    return async (dispatch: Dispatch) => {
        dispatch(getCurrentUserRequest());
        if (localStorage.getItem("accessToken")) {
            return ClientUserActions.getCurrent()
                .then((response: any) => {
                    dispatch(getCurrentUserSuccess(response.data));
                    return response.data;
                })
                .catch(() => {
                    localStorage.setItem("accessToken", "");
                    return dispatch(getCurrentUserFailure());
                });
        } else {
            dispatch(getCurrentUserFailure());
            localStorage.setItem("accessToken", "");
            return Promise.reject();
        }
    };
};

export const logout = () => {
    return (dispatch: Dispatch) => {
        localStorage.setItem("accessToken", "");
        dispatch(logoutSuccess());
    };
};

export type UserActions = ActionType<
    | typeof getCurrentUserRequest
    | typeof getCurrentUserFailure
    | typeof getCurrentUserSuccess
    | typeof logoutSuccess
>;

export const userReducer = (state: BexioUser = defaultUser, action: UserActions) => {
    switch (action.type) {
        case "user/GET_CURRENT_SUCCESS":
            return action.payload;
        case "user/LOGOUT_SUCCESS":
            return defaultUser;
        default:
            return state;
    }
};