import React from "react";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Hidden from "@mui/material/Hidden";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Navigation from "../navigation/Navigation";
import { useSelector } from "react-redux";
import { Link, Link as RouterLink } from "react-router-dom";
import { AppState } from "../../interfaces/general/app-state";
import { BexioUser } from "../../interfaces/bexio/BexioUser";

export const drawerWidth = 250;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    drawer: {
      [theme.breakpoints.up("lg")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      backgroundColor: "white",
      color: theme.palette.primary.main,
      boxShadow: "0px 0px 40px 0px rgb(82 63 105 / 10%)",
      [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    drawerPaper: {
      backgroundColor: theme.palette.primary.main,
      width: drawerWidth,
      color: "#fff",
      overflow: "hidden"
    },
    title: {
      flexGrow: 1,
      lineHeight: 1,
      color: "black"
    },
    link: {
      color: "black",
      textDecoration: "none",
    },
  })
);

const Header: React.FC = () => {
  const classes = useStyles();
  const user = useSelector<AppState, BexioUser>((state) => state.user);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };


  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="profileMenu"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        component={RouterLink} to="/logout" onClick={handleMenuClose}>
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <div className={classes.drawer}>
        <Hidden lgUp implementation="css">
          <SwipeableDrawer
            anchor={"left"}
            open={isOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Navigation toggleDrawer={toggleDrawer} />
          </SwipeableDrawer>
        </Hidden>
        <Hidden lgDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Navigation toggleDrawer={toggleDrawer} />
          </Drawer>
        </Hidden>
      </div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            size="large">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            <RouterLink to="/" className={classes.link}>
              Chili Abo Verwaltung
            </RouterLink>
          </Typography>
          {user && user.id ? (
            <MenuItem onClick={handleMenuOpen}>
              <IconButton
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="primary"
                size="large">
                <AccountCircleIcon />
              </IconButton>
              <p>
                {user.firstname} {user.lastname}
              </p>
            </MenuItem>
          ) : (
            <Button color="primary" variant="outlined" component={Link} to="/login">
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
};

export default Header;
