import axios from "axios";

export const get = (id: number) => {
    return axios.get(`/contacts/get?id=${id}`);
}

export const getSubContacts = (contactId: number) => {
    return axios.get(`/contacts/getSubContacts?contactId=${contactId}`);
}

export const getByQuery = (query : string) => {
    return axios.get(`/contacts/getByQuery?query=${query}`);
}

export const syncWithBexio = () => {
    return axios.get("/contacts/syncWithBexio");
};

