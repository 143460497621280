import { BexioUser, defaultUser } from "../bexio/BexioUser";
import { Request } from "./Request";

export interface AppState {
  requests: Request[];
  user: BexioUser;
}

export const initialState: AppState = {
  requests: [],
  user: defaultUser
};
