import * as React from "react";
import { Route, Redirect, useLocation } from "react-router";
import { RouteProps } from "react-router-dom";
import Loading from "../loading/Loading";

interface Props extends RouteProps {
  authed: boolean;
  isLoading: boolean;
}

const ProtectedRoute: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const currentUrlEncoded = location.pathname != "/login/" ? encodeURIComponent(location.pathname) : "";
  const getRedirectUrl = () => {
    if (location.pathname != "/") return currentUrlEncoded;
    return "";
  };
  return props.isLoading ? (
    <Loading isLoading />
  ) : (
    <Route
      {...props}
      render={(renderProps) =>
        props.authed ? (
          <>{props.render(renderProps)}</>
        ) : (
          <Redirect to={"/login/" + getRedirectUrl()} />
        )
      }
    />
  );
};

export default ProtectedRoute;